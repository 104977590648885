import React from 'react'
import Footer from '../components/layoutComponents/Footer'
import Header from '../components/layoutComponents/Header'

// keep this page and add am image and links to login and registration?  or get rid of it and have the "home page" default to login page?

const HomePage = () => {
    return (
        <div>
            <Header />





            <Footer />
        </div>
    )
}

export default HomePage
